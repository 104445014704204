import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import linkedIn from '../../assets/img/linkedin.svg';
import linkedInMobile from '../../assets/img/linkedin(mobile).svg';
import avenuFooterLogo from '../../assets/img/brand/avenue-footer.svg';
import './Footer.scss';

const Footer = () => {
    return (
        <Fragment>
            <Row className="footer">
                <Col md='3' className="d-none d-md-block">
                    <div className="logo-container mt-30">
                        <Link to={`/`}>
                            <img src={avenuFooterLogo} alt="avenue" className="logo-text"/>
                        </Link>
                    </div>
                </Col>
                <Col md='3' className="d-none d-md-block">
                    <div className="mt-30 container-links">
                        <div><Link to={`/`}>Home</Link></div>
                        <div><Link to={`/about`}>About Us</Link></div>
                        <div><Link to={`/product`}>Our Product</Link></div>
                    </div>
                </Col>
                <Col md='3' className="d-none d-md-block">
                    <div className="mt-30 container-links">
                        <div><Link to={`/providers`}>Providers</Link></div>
                        <div><Link to={`/payors`}>Payors</Link></div>
                        <div><Link to={`/contact`}>Contact</Link></div>
                    </div>
                </Col>
                <Col md='3' className="d-none d-md-block">
                    <div className="mt-30 title">
                        <h4>Social</h4>
                    </div>
                    <div className=" mt-30 social-container">
                        <a className="no-underline" href={`https://www.linkedin.com/company/avenu-care/`} target={'_blank'}><img src={linkedIn} alt="avenu" className="logo-linkedin"/></a>
                    </div>
                </Col>
                <Col md='12 text-center' className="footer-copyright">
                    <div className={'linkedin-mobile-logo'}>
                        <a className="no-underline"
                       href={`https://www.linkedin.com/company/avenu-care/`}
                       target={'_blank'}><img src={linkedInMobile} alt="avenu" className="logo-linkedin"/></a>
                    </div>

                    © Copyright 2020
                </Col>
            </Row>
        </Fragment>
    )
};

export default Footer;
