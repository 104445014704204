import React from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { history } from './Utils/history';
import Home from './Pages/Home';
import About from './Pages/About';
import Product from './Pages/Product';
import Providers from './Pages/Providers';
import Payors from './Pages/Payors';
import Contact from './Pages/Contact';

function App() {
  return (
      <Router history={history}>
          <Switch>
              <Route exact path={`/`} component={ Home } />
              <Route exact path={`/about`} component={ About } />
              <Route exact path={`/product`} component={ Product } />
              <Route exact path={`/providers`} component={ Providers } />
              <Route exact path={`/payors`} component={ Payors } />
              <Route exact path={`/contact`} component={ Contact } />
          </Switch>
      </Router>
  );
}

export default App;
