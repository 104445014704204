import React, {Fragment, useEffect} from 'react';
import {Helmet} from "react-helmet";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import {Col, Container, Row} from "reactstrap";
import ContactUs from "../../Components/ContactUs";

import About1 from '../../assets/img/about/about-1.png';
import About1Large from '../../assets/img/about/about-1(xl).png';
import About2 from '../../assets/img/about/about-2.png';
import About3 from '../../assets/img/about/about-3.png';
import About3Large from '../../assets/img/about/about-3(xl).png';

import ZiaRahman from '../../assets/img/about/zia_rahman.png';
import MarkSilverman from '../../assets/img/about/mark_silverman.png';
import RobertLongyear from '../../assets/img/about/robert_longyear.png';
import RichardBoxer from '../../assets/img/about/richard.png';
import Kelley from '../../assets/img/about/kelley.png';
import Christine from '../../assets/img/about/christine.png';
import Robin from '../../assets/img/about/robin.png';
import Deborah from '../../assets/img/about/deb.png';
import './About.scss';
import SEO from "../../Components/SEO";

const About = () => {
  const teamMembers = [
  {
    name: "Zia Rahman",
    title: "CEO & Founder",
    img: ZiaRahman,
    urlLinkedin: 'https://www.linkedin.com/in/rahmanzia/'
  },
  {
    name: "Mark Silverman, JD",
    title: "COO",
    img: MarkSilverman,
    urlLinkedin: 'https://www.linkedin.com/in/mark-silverman-9635541a/'
  },
  {
    name: "Robert Longyear",
    title: "VP Digital Health and Innovation",
    img: RobertLongyear,
    urlLinkedin: 'https://www.linkedin.com/in/robert-l-longyear-iii-a8b284b9'
  },
  {
    name: "Richard Boxer, MD",
    title: "Chief Medical Advisor",
    img: RichardBoxer,
    urlLinkedin: 'https://www.linkedin.com/in/richard-boxer-md-facs-264b0625/'
  },
  {
    name: "Christine Wissell, BSN, RN",
    title: "Director of Clinical Transformation",
    img: Christine,
    urlLinkedin: 'https://www.linkedin.com/in/christinewissellrnbsn/',
    class: "offset-sm-3"
  },
  {
    name: "Robin Walton, BSN, RN, CCM, MHA",
    title: "RPM Clinical Lead",
    img: Robin,
    urlLinkedin: 'https://www.linkedin.com/in/robin-walton/'
  }];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <Fragment>
      <SEO page="about" />
      <Header active="about" css={'spring-wood'}/>

      <Container fluid className="bg-color-spring-wood about-page">
        <div className="container no-home pt-md-5 pb-lg-5">
          <Row>
            <Col md={12} className="pb-lg-3 pb-md-3">
              <h1>About Us</h1>
            </Col>
            <Col md={12}>
              <p className="subheading lh-1-5 full-width">
                We stand for proactive care models and big patient outcomes. Our mission is
                to support human clinicians in developing scalable virtual care models that
                are built for the patient.
              </p>
            </Col>
          </Row>
        </div>
      </Container>

      <div className="about-us">
        <section>
          <Row>
            <Col md={12}>
              <h2 className="text-center pt-5 pb-3">Why We Do What We Do</h2>
            </Col>
          </Row>
        </section>

        <Container fluid className="spring-wood-line-bg-mission">
          <div className="container-fluid-avenu">
            <section className={'d-flex'}>
              <Row>
                <Col md='6' className={'text-center pl-0'}>
                  <img src={About1} className={'section-image float-left'} alt={'Facility'}/>
                  <img src={About1Large} className={'section-image float-left large'} alt={'Facility'}/>
                </Col>
                <Col md='6' lg={'5'} className={'align-self-center section-text'}>
                  <h3>We stand for patients.</h3>
                  <p className="body-copy-large lh-1-5">Healthcare is a mess. We have made it our mission to support the implementation of high-quality care models with our healthcare organization partners. By supporting our partners, we believe we can fundamentally impact patient care.</p>
                </Col>
              </Row>
            </section>
          </div>
        </Container>

        <Container fluid className="believe-in-human">
          <div className="container-fluid-avenu">
            <section className={'d-flex'}>
              <Row className={'mobile-reverse'}>
                <Col md='6' lg={'5'} className={'section-text align-self-center offset-lg-1 pr-3'}>
                  <h3>We believe in human clinicians.</h3>
                  <p className="body-copy-large p-top-20 lh-1-5">
                    We think human clinicians are the core of healthcare.
                    Our roots are in nurse staffing where we have met
                    dedicated clinicians searching for ways to better
                    support their patients.
                  </p>
                </Col>
                <Col md='6' xl={5}>
                  <img src={About2} className={'section-image float-right'} alt={'Clinician'}/>
                </Col>
              </Row>
            </section>
          </div>
        </Container>

        <Container fluid className="technologies-extend-capabilities">
          <div className="container-fluid-avenu">
            <section className={'d-flex'}>
              <Row>
                <Col md='6' className={'text-center pl-0'}>
                  <img src={About3} className={'section-image float-left'} alt={'Facility'}/>
                  <img src={About3Large} className={'section-image float-left large'} alt={'Facility'}/>
                </Col>
                <Col md='6' lg={'5'} className={'align-self-center section-text'}>
                  <h3>We build technologies that extend capabilities.</h3>
                  <p className="body-copy-large p-top-20 lh-1-5">
                    Our technologies better connect patient data and facilitate
                    communication to enhance the capabilities of clinicians. Technology is a powerful tool to equip human providers of care
                    to positively impact patients.
                  </p>
                </Col>
              </Row>
            </section>
          </div>
        </Container>

        <Container fluid className="our-team outcomes-pearl">
          <div className="container-fluid-avenu">
            <section className={'row'}>
              <Col sm={12} className="text-center">
                <h2> Our Team </h2>
              </Col>
              {
                React.Children.toArray(
                  teamMembers.map((item) => {
                    return  <Col sm={3} className={(item.class || "") +  " m-top-40"}>
                      <div className="text-center p-top-20">
                        <a className="no-underline" href={item.urlLinkedin} target={'_blank'}>
                          <img src={item.img} className="photo rounded-circle" alt={item.name} />
                        </a>
                      </div>
                      <a className="no-underline" href={item.urlLinkedin} target={'_blank'}>
                        <h3 className="text-center p-top-20">
                          {item.name}
                        </h3>
                      </a>
                      <div className="body-copy-large text-center">
                        {item.title}
                      </div>
                    </Col>
                  })
                )
              }
            </section>
          </div>
        </Container>

      </div>
      <ContactUs/>
      <Footer/>
    </Fragment>
  )
};

export default About;
