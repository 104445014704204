import React, {Component, Fragment} from 'react';
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import {AvFeedback, AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {Button, Col, Container, Row, Alert} from "reactstrap";
import LinkedInLogo from '../../assets/img/LinkedinLogo.svg';
import LocationLogo from '../../assets/img/location.svg';
import $ from 'jquery';
import ReCAPTCHA from "react-google-recaptcha";
import 'jquery-mask-plugin/dist/jquery.mask.min';
import './contact.scss';
import {Helmet} from "react-helmet";
import SEO from "../../Components/SEO";

const copyOptions = {
    provider: 'Find out how Avenue can be a revenue center for your practice while we help provide excellent care management services to your patients. Our virtual care team model is fully managed by us so we do not impact your existing workflows.',
    payor: 'Our payor programs allow for rapid deployment of care management interventions. With Avenue, you can target new patient populations or scale your existing programs to impact a larger number of members—on demand.',
    risk: 'The most successful value-based programs require new models of care that can drive patient outcomes. Our uniquely scalable care management model supports your patients under risk contracts so, together, we can reduce emergency department visits and hospitalizations.'
};

const url = 'https://www.avenue.healthcare/email/sendEmail.php';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.state = {
      selectedCopy: '',
      fullname: false,
      email: false,
      phone: false,
      typeContact: '',
      captchaPassed: true,
      captchaInvalidMessage: '',
      visibleAlert: false
    };
  }

  componentDidMount() {
      window.scrollTo(0, 0);
      const paramsString = window.location.search;

      if (paramsString) {
          const params = new URLSearchParams(paramsString);
          const type = params.get('type');

          this.setState({
              typeContact: type,
              selectedCopy: copyOptions[type],
              selectedEmail: 'info@avenu.care',
          })
      }

    $('[name="phone"]').mask('0000000000');
  }

  handleValidSubmit(event, values) {
    this.setState({values});

    if (this.state.captchaPassed) {
        this.setState({
            captchaInvalidMessage: ''
        });

        const data = {
            ...values,
            selectedEmail: this.state.selectedEmail
        };

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
            this.setState({ visibleAlert: true });
            this.form.reset();
            this.captcha.reset();
        });

    } else {
        this.setState({ captchaInvalidMessage: 'The captcha is required' });
    }
  }

  handleInvalidSubmit(event, errors, values) {
    this.setState({errors, values});
  }

  onChangeSelect (event) {
    this.setState({
      selectedCopy: copyOptions[event.target.value],
      selectedEmail: 'info@avenu.care',
    });
  }

  onChangeCaptcha = (value) => {
      this.setState({
          captchaPassed: value,
          captchaInvalidMessage: ''
      });
  };

  onDismiss = () => {
    this.setState({visibleAlert: false})
  };

  render() {
    const {selectedCopy, typeContact, selectedEmail, captchaInvalidMessage} = this.state;

    return (
      <Fragment>
        <SEO page="contact" />
        <Header active="contact" css={'spring-wood'}/>

        <Container fluid className="bg-color-spring-wood">
          <div className="container pt-md-5 pb-lg-5">
            <Row>
              <Col md={12} className="pb-lg-3 pb-md-3">
                <h1>Get In Touch</h1>
              </Col>
            </Row>
          </div>
        </Container>

        <section className={'page-content'}>
          <Container>
            <Row>
                <Col md={12}>
                    <Alert color="success alert-success-mail" isOpen={this.state.visibleAlert} toggle={this.onDismiss}>
                        The email was sent successfully, we will contact you soon.
                    </Alert>
                </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit} ref={c => (this.form = c)}>
                  <AvGroup>
                    <AvInput type="select" name="help" id="helpSelect" onChange={this.onChangeSelect.bind(this)} required value={typeContact}>
                      <option value={''} disabled>How can we help you?</option>
                      <option value="provider"> I'm a provider and I'd like to know more </option>
                      <option value="payor"> I'm a payor and I'd like to know more </option>
                      <option value="risk"> I'm a At Risk Program and I'd like to know more </option>
                      <option value="other"> Other </option>
                    </AvInput>
                    <AvFeedback>This field is required</AvFeedback>
                  </AvGroup>
                  <AvGroup>
                    <AvInput type="text" name="fullname" placeholder={'Full Name'} required/>
                    <AvFeedback>This field is required</AvFeedback>
                  </AvGroup>
                  <AvGroup>
                    <AvField name="email" placeholder="Email address" type="email" required/>
                  </AvGroup>
                  <AvGroup>
                    <AvInput type="text" name="phone" placeholder={'Phone number'} required/>
                    <AvFeedback>This field is required</AvFeedback>
                  </AvGroup>
                    <ReCAPTCHA
                        ref={e => (this.captcha = e)}
                        sitekey={`${process.env.REACT_APP_CAPTCHA_TOKEN}`}
                        onChange={this.onChangeCaptcha}
                    />
                    <div className="invalid-captcha m-bottom-30">{captchaInvalidMessage}</div>
                  <Button color={'primary'} className={'btn-wide'}>Send</Button>
                </AvForm>
              </Col>
              <Col sm={5} className={'offset-md-1'}>
                {selectedCopy ?
                  <div className={'mb-4 copy-text'}>{selectedCopy}</div>
                  : null}
                <div className={'mb-2'}>You can also email us directly at:</div>
                <a href="mailto:info@avenue.healthcare">info@avenue.healthcare</a>
                <div className={'mt-4 mb-2'}>
                  Follow us on social media:</div>
                <a className="no-underline" href={`https://www.linkedin.com/company/avenu-care/`} target={'_blank'}>
                  <img src={LinkedInLogo} alt="Linked In Logo"/>
                </a>
              </Col>
            </Row>
          </Container>
        </section>
        <Container fluid>
          <section className={'row avenu-address justify-content-center'}>
            <div className={'text-center'}>
              <img src={LocationLogo}/><br/>
              <a href={'https://goo.gl/maps/2GKdfV7gucWePmnA9'} target={'_blank'} className={'inline-block mt-3'}>One
                World Trade Center, 8th Floor,
                Long Beach, CA 90831</a>
            </div>
          </section>
        </Container>
        <Footer/>
      </Fragment>
    )
  }
}

export default Contact;
